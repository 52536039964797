import { NavLink} from "react-router-dom";

export function LinkButton({text, url}) {
    return (
        <a className="rounded-full border border-white px-6 py-3 bg-transporent text-white hover:bg-white hover:text-black" href={url}>{text}</a>
    );
}

export function LinkProminentButton({text, url}) {
    return (
        <a className="rounded-full border border-white px-6 py-3 bg-white text-black hover:bg-transparent hover:text-white" href={url}>{text}</a>
    );
}

export function NavLinkButton({text, url}) {
    return (
        <NavLink className="rounded-full border border-white px-6 py-3 bg-transporent text-white hover:bg-white hover:text-black" to={url}>{text}</NavLink>
    );
}

export function NavLinkProminentButton({text, url}) {
    return (
        <NavLink className="rounded-full border border-white px-6 py-3 bg-white text-black hover:bg-transparent hover:text-white" to={url}>{text}</NavLink>
    );
}

