import { useEffect } from 'react';
import Animation from "./../components/animation";
import DefaultLayout from "./../layouts/default";
import { LinkProminentButton } from "./../components/button";

export default function License() {
    useEffect(() => { document.title = 'Licensing'; }, []);

    return (
        <DefaultLayout>
            <div className="mx-auto max-w-4xl px-8 md:pt-24 pt-16 pb-16 text-lg text-platinum">
                <h1 className="md:text-4xl text-3xl">Licensing</h1>
                <Animation>
                    <div>
                        <h2 className="text-2xl pt-16">Under $1 million USD in revenue?</h2>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 pt-6">
                            <p>No fees.</p>
                            <p>Companies who made up to 1 million USD in proceeds in the prior calendar year for all their apps and games can qualify for the small business program.</p>
                            <p>To participate in the program, you must have earned no more than 1 million USD in total proceeds during the 12 fiscal months occurring within the previous calendar year, and have earned no more than 1 million USD during the current year.</p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div>
                        <h2 className="text-2xl pt-16">Over $1 million USD in revenue?</h2>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 pt-6">
                            <p>Pay as you go. No up-front or termination charges.</p>
                            <p>Royalties apply after $1 million USD gross product revenue. All lifetime gross revenue above $1M that is directly attributable to the product, will be subject to a 2% royalty. Revenues generated in other ways are royalty-free.</p>
                        </div>
                    </div>
                </Animation>
            </div>
            <Animation>
                <div className="flex flex-col-1 min-h-screen items-center text-center md:pb-64 pb-48 bg-center bg-no-repeat bg-cover bg-[linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0.96),rgba(0,0,0,0.86),rgba(0,0,0,0.24),rgba(0,0,0,1)),url('../public/images/2.png')]">
                    <div className="mx-auto md:min-h-96 min-h-80">
                        <h3 className="md:text-4xl text-3xl text-center text-white pb-4">Get started today</h3>
                        <p className="md:text-xl text-lg font-medium text-platinum opacity-90">Simply complete a few fields.</p>
                        <div className="pt-8"><LinkProminentButton text="Apply now" url="/apply" /></div>
                    </div>
                </div>
            </Animation>
        </DefaultLayout>
    );
}
