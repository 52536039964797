import { useEffect } from 'react';
import DefaultLayout from "./../layouts/default";

export default function PrivacyPolicy() {
    useEffect(() => { document.title = 'Privacy Policy'; }, []);

    return (
        <DefaultLayout>
            <div className="mx-auto max-w-4xl px-8 md:pt-24 pt-16 pb-16 text-lg text-platinum">
                <div className="max-w-prose space-y-4">
                    <h1 className="md:text-4xl text-3xl md:pb-6 pb-4">Privacy Policy</h1>
                    <p>Updated February 2, 2024</p>
                    <p>The mighty kittens ("us", "we", "our") is committed to protecting your privacy across the globe. This Privacy Policy outlines how we collect, use, and protect your data in compliance with various data protection laws, including the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), the Asia-Pacific Privacy Laws, and others.</p>
                    <p>By using our website, you consent to the practices described in this Privacy Policy.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Information We Collect</h2>
                    <p>While The mighty kittens do not store personal data, we may collect non-personal information (or "usage data") including your IP address, browser type, operating system, the date and time of your visit, and other similar data. Usage Data may also include pages visited, time spent, unique device identifiers, and diagnostic data. When you access our website via a mobile device, this Usage Data may include device type, mobile device unique ID, IP address, mobile operating system, mobile browser, unique device identifiers and other diagnostic data.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Purpose of Data Collection, Processing and/or Retention</h2>
                    <p>The mighty kittens may only collect, process, or retain your personal information for the purposes that you have consented to or that are within our legitimate interests. We delete or anonymize data when it is no longer needed.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Sensitive Personal Information</h2>
                    <p>Protecting your privacy to the maximum extent possible is our goal at The mighty kittens. Accordingly, we do not process sensitive personal information unless we have obtained your explicit consent, nor do we store your personal information on our servers. When you use our website, however, certain Personal Information such as IP address, user-agent information (which is automatically sent by your web browser and may include device type) and information collected by cookies and other similar technologies may be automatically collected.</p>

                    <h2 className="text-2xl font-semibold py-2 md:pt-12 pt-8">Legal Basis for Processing</h2>
                    <p>Our data processing is based on various legal bases, including user consent, contractual necessity, compliance with legal obligations, and legitimate interests, as defined by relevant data protection laws in different regions.</p>

                    <h2 className="text-2xl font-semibold py-2 md:pt-12 pt-8">How We Use Your Information</h2>
                    <p>We use your non-personal information for the following purposes:</p>
                    <ul className="list-disc list-inside">
                        <li>Providing and improving our services.</li>
                        <li>Communicating with you about our products and services.</li>
                        <li>Responding to inquiries and support requests.</li>
                        <li>Analyzing and enhancing our website and services.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Data Security</h2>
                    <p>The mighty kittens is committed to safeguarding your personal information and has implemented reasonable measures to prevent unauthorized access, use, or disclosure of your data. While we take every precaution to ensure the security of your information, it's important to note that no online transmission or storage can be guaranteed to be entirely secure. By using The mighty kittens’ website, you acknowledge and accept that there are inherent risks associated with online data transmission and storage, and you agree to proceed with this understanding.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">International Data Transfers</h2>
                    <p>Your data may be transferred internationally. Accordingly, The mighty kittens implements reasonably appropriate safeguards to protect your data, as required by applicable laws.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Limitation of Liability</h2>
                    <p>To the fullest extent permissible under applicable law, The mighty kittens, its directors, employees, affiliates, and partners shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses arising out of or in connection with your use of our website or services. Your use of the The mighty kittens website and services is at your own risk, and you agree that this limitation of liability is a fundamental basis of the agreement between you and The mighty kittens.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Third-Party Links and Service Providers</h2>
                    <p>To enhance your experience and convenience, our website may contain links to third-party websites. We want to emphasize, however, that we are not responsible for the privacy practices or content of these sites. Additionally, we may collaborate with third-party companies ("Service Providers") to improve our services. These Service Providers may access your Personal Data solely for performing tasks on our behalf, and they are bound by confidentiality agreements. It's important to remember that your interactions with third-party websites are subject to their own privacy policies.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Individual Rights</h2>
                    <p>You have the right to access, rectify, and delete your personal information. You may also object to or restrict processing, and request data portability. To exercise these rights, please contact us.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Complaints and Supervisory Authority</h2>
                    <p>If you believe we haven't complied with applicable data protection laws, you can lodge a complaint with a supervisory authority. Contact details for supervisory authorities can be found in relevant data protection regulations.</p>

                    <h2 className="text-2xl font-semibold py-2 pt-8">Updates to this Policy</h2>
                    <p>We may update this Privacy Policy periodically. We will notify you of significant changes through email or prominent notices on our website. Your continued use of our website constitutes your acceptance of the updated Privacy Policy.</p>
                </div>
            </div>
        </DefaultLayout>
    );
}
