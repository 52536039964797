import { motion, Variants } from "framer-motion";

export default function Animation(content) {
    const variants: Variants = {
        off: {
            opacity: 0,
            y: 5,
        },
        on: {
            opacity: 1,
            y: 0,
            transition: {
                type: "tween",
                duration: 0.7,
                ease: "easeInOut",
            },
        },
    };

    return (
        <motion.div initial="off" whileInView="on" viewport={{ once: false, amount: 0.6 }}>
            <motion.div variants={variants}>{ content.children }</motion.div>
        </motion.div>
    );
}
