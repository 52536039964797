import { NavLinkButton, NavLinkProminentButton } from "./../components/button";
import Badges from "./../components/badges";
import MainLayout from "./../layouts/main";
import { useEffect } from "react";

export default function Main() {
    
    useEffect(() => { document.title = 'The mighty kittens'; }, []);
    
    return (
        <MainLayout>
            <div className="mx-auto max-w-4xl px-8 md:pt-32 pt-16 main">
                <h1 className="text-[34px] md:text-[56px] md:mb-[0.5em] mb-[0.5em] md:max-w-[90%] lg:max-w-[70%]">The world's biggest and fastest marketplaces.</h1>
                <p class="md:block hidden mb-4 md:font-medium display text-[17px] md:text-[20px] md:max-w-[82%] leading-snug md:leading-relaxed tracking-[0.011em]">Integrate the world's biggest marketplaces in seconds. Embark gamers with wallet, trusted by over 100 million users. Take advantage of Web 3 marketing tools and on-chain analytics. </p>
                <div className="">
                    <Badges/>
                </div>
                <div className="md:py-14 pt-10 md:space-x-5 space-x-4">
                    <NavLinkProminentButton text="Learn more" url="/overview" />
                    <NavLinkButton text="Apply now" url="/apply" />
                </div>
            </div>
        </MainLayout>
    );
}
