import Layout from "./layout";

export default function ThanksLayout(content) {
    return (
        <div className="flex flex-col min-h-dvh
             bg-center bg-no-repeat bg-cover bg-[linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0.96),rgba(0,0,0,0.86),rgba(0,0,0,0.24),rgba(0,0,0,1)),url('../public/images/2.png')]
        ">
            <Layout>{ content.children }</Layout>
        </div>
    );
}