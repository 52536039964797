import Animation from "./../components/animation";
import DefaultLayout from "./../layouts/default";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "./../components/firebase";
import { useEffect, useState } from "react";

export default function Apply() {

    useEffect(() => { document.title = 'Get started'; }, []);

    const [isSent, setIsSent] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");

    const orders = collection(firestore, "orders");

    const apply = async () => {
        try {
            if (firstName === "" || lastName === "" || emailAddress === "" || companyName === "" || websiteUrl === "") {
                setIsValid(false);
                return;
            }
            setIsValid(true);
            setIsSending(true);
            await addDoc(orders, {
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                companyName: companyName,
                websiteUrl: websiteUrl,
            });
            setIsSending(false);
            setIsSent(true);
        } catch (error) {
        }
    };

    if (isSent) {
        return (
            <DefaultLayout>
                <Animation>
                    <div className="md:pt-0 pt-16 md:flex md:flex-col-1 md:min-h-screen md:items-center text-center md:pb-16 pb-0 bg-center bg-no-repeat bg-cover md:bg-[linear-gradient(to_bottom,rgba(0,0,0,0.96),rgba(0,0,0,0.96),rgba(0,0,0,0.86),rgba(0,0,0,0.24),rgba(0,0,0,1)),url('../public/images/2.png')]">
                        <div className="mx-auto md:min-h-96 min-h-32">
                            <h3 className="text-4xl text-center text-white pb-4">Thank you</h3>
                            <p className="text-xl font-medium text-platinum opacity-90">We will get in touch with you.</p>
                        </div>
                    </div>
                </Animation>
            </DefaultLayout>
        );
    }

    return (
        <DefaultLayout>
            <div className="pb-0">
                <div className="mx-auto max-w-4xl px-8 md:pt-16 pt-8 pb-16">
                    <div className="max-w-xl">
                        <h1 className="md:text-4xl text-3xl md:py-8 pb-8 pt-8 text-platinim">Get started</h1>
                        <Animation>
                            <p className="text-platinim">Enter your legal name, email and website.</p>
                            <input name="firstName" className="w-full px-3 py-2 md:mt-6 mt-5 rounded-[4px] text-base text-primary" type="text" autoComplete="given-name" placeholder="First name" onChange={(e) => {setFirstName(e.target.value);setIsValid(true);}} />
                            <input name="lastName" className="w-full px-3 py-2 md:mt-8 mt-5 rounded-[4px] text-base text-primary" type="text" autoComplete="family-name" placeholder="Last name" onChange={(e) => {setLastName(e.target.value);setIsValid(true);}} />
                            <input name="emailAddress" className="w-full px-3 py-2 md:mt-8 mt-5 rounded-[4px] text-base text-primary" type="text" autoComplete="email" placeholder="Email address" onChange={(e) => {setEmailAddress(e.target.value);setIsValid(true);}} />
                            <input name="companyName" className="w-full px-3 py-2 md:mt-8 mt-5 rounded-[4px] text-base text-primary" type="text" placeholder="Company name" onChange={(e) => {setCompanyName(e.target.value);setIsValid(true);}} />
                            <input name="websiteUrl" className="w-full px-3 py-2 md:mt-8 mt-5 rounded-[4px] text-base text-primary" type="text" placeholder="Website URL" onChange={(e) => {setWebsiteUrl(e.target.value);setIsValid(true);}} />
                            <p className="md:mt-6 mt-4 text-platinim md:text-sm text-sm">By clicking Continue, you agree to the <a className="underline" href="/privacy">Privacy Policy</a>.</p>
                            { ! isValid && (<div className="uppercase text-platinim pt-4">Please complete all the fields</div>) }
                            <div className="pt-8">
                                <button onClick={apply} disabled={isSending} className="rounded-full border border-white px-6 py-2 bg-transporent text-white hover:bg-white hover:text-black">{isSending ? "Please wait" : "Continue"}</button>
                            </div>
                        </Animation>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}
