import "./index.css";
import Apply from "./routes/apply";
import License from "./routes/license";
import Main from "./routes/main";
import Overview from "./routes/overview";
import PrivacyPolicy from "./routes/privacy";
import NotFound from "./routes/error";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "",
        errorElement: <NotFound/>,
        children: [
            { index: true, element: <Main/> },
            { path: "overview", element: <Overview/> },
            { path: "license", element: <License/> },
            { path: "apply", element: <Apply/> },
            { path: "privacy", element: <PrivacyPolicy/> },
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
