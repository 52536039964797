import Nav from "./../components/nav";
import { useState } from "react";

export default function Layout(content) {
    
    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            <header>
                <Nav isVisible={isVisible} setIsVisible={setIsVisible} />
            </header>
            <main className="flex-grow">
                { content.children }
            </main>
            <footer>
                <div className="mx-auto max-w-4xl px-8 md:py-8 pb-8 lg:flex lg:space-x-4 lg:space-y-0 space-y-1 text-xs text-secondary">
                    <div>Copyright © 2024 The mighty kittens. All rights reserved.</div>
                    <div><a className="hover:underline" href="/privacy">Privacy Policy</a></div>
                </div>
            </footer>  
        </>
    );
}
