import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyC0dJ1fOaWQ0LmdAcCKAvpR4_CNRJKtzfM",
    authDomain: "themightykittens-6c7f7.firebaseapp.com",
    projectId: "themightykittens-6c7f7",
    storageBucket: "themightykittens-6c7f7.appspot.com",
    messagingSenderId: "616171724181",
    appId: "1:616171724181:web:600224940c285cad8b6a9c",
    measurementId: "G-W5EWY59665"
};

const app = initializeApp(config);
export const firestore = getFirestore(app);
