import { useEffect } from 'react';
import Animation from "./../components/animation";
import DefaultLayout from "./../layouts/default";
import { LinkProminentButton } from "./../components/button";

export default function Overview() {
    useEffect(() => { document.title = 'Overview'; }, []);
    return (
        <DefaultLayout>
            <div className="mx-auto max-w-4xl px-8 md:pt-24 pt-16 md:pb-16 pb-8 text-platinum">
                <Animation>
                    <div className="pb-8 md:text-4xl text-3xl">
                        <h3>1 ecosystem.</h3>
                        <h3>4 marketplaces.</h3>
                        <h3>200M+ addresses.</h3>
                        <h3>10B+ sales volume.</h3>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:pt-8 pb-6">
                        <div className="max-w-prose text-xl opacity-80">
                            <p>
                                Integrate the world's biggest NFT marketplaces in seconds. Embark gamers with wallet, trusted by over 100 million users. Take advantage of Web 3 marketing tools and on-chain analytics.
                            </p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:py-8 pb-6 pt-2">
                        <h3 className="text-3xl">Game-changing.</h3>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 mt-4">
                            <p>
                                Unlike traditional game experience where in-game purchases remain bound to a game and have zero real-world value, NFT marketplaces allow gamers to own their purchases as investable assets. They become collectibles that gamers can trade or just hold onto. It enhances the gaming experience and offers potentially very profitable investment in NFTs.
                            </p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:py-8 py-6">
                        <h3 className="text-3xl">For scale and growth.</h3>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 mt-4">
                            <p>
                                Get more users and make more money on the world's biggest and fastest NFT marketplaces.
                                Every time you list an asset it is instantly distributed to multiple marketplaces.
                                It empowers you to scale your game assets distribution, thier liquidity and market value.
                            </p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:py-8 py-6">
                        <h3 className="text-3xl">Web3 marketing tools.</h3>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 mt-4">
                            <p>
                                Easily create custom marketing assets in multiple languages — such as banners, gifs, mems and other images and videos — to share on social media, messangers or banner networks.
                                Generate short links or embeddable code that lead to your assets, a QR code, app's badges.
                                Provide bonuses, cash-back, pre-orders, promo codes, referral program, staking rewards.
                                All you need is built-in.
                            </p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:py-8 py-6">
                        <h3 className="text-3xl">On-chain analytics.</h3>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 mt-4">
                            <p>
                                Measure your app's, game's and drop's performance with metrics, charts, ranks and trends. Measure the performance of marketing campaigns across channels, marketplaces and chains. See your summary — by week, by month, by quarter, or by year. Get daily data, trends, forecasts and AI suggestions to improve your on-chain conversions.
                            </p>
                            <p>
                                Find out how people discover and engage with your digital assets, see retention rates, wallet conversion rates, unique paying and staking users, trading volume, average hold, in-game transactions, and more than a hundred of other performance metrics.
                            </p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:py-8 py-6">
                        <h3 className="text-3xl">Trusted wallets.</h3>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 mt-4">
                            <p>
                                Embark gamers, NFT collectors and enthusiasts with Connect wallet, trusted by over 100 million users.
                                Add multi-chain support to your project.
                                It’s ready and set, with built-in support for more than 30 hardware and software wallets, including all industry leaders: Ledger, MetaMask, Wallet Connect, Coinbase, etc.
                            </p>
                            <p>
                                Natively supports all EVM chains on Layer 1 and Layer 2 blockchain ecosystem, scaling Ethereum with real-time solutions, including Ethereum, Base, BNB Chain, Celo, Gnosis, Polygon, and all other EVM networks.
                            </p>
                            <p>
                                Real-time transaction notifications for all connected wallet addresses and all transaction states. In-notification speedups and cancels for hardware wallet connections.
                            </p>
                        </div>
                    </div>
                </Animation>
                <Animation>
                    <div className="md:py-8 py-6">
                        <h3 className="text-3xl">Financial reports.</h3>
                        <div className="max-w-prose text-xl opacity-80 space-y-4 mt-4">
                            <p>
                                There are more than a hundred of reports available, including daily, monthlty, quarter and annual reports, as well as poweful report builder.
                                View your final proceeds for each month, with earnings broken out by chain, maketpalce, acquisition channel, NTF collection and item.
                            </p>
                            <p>
                                Access detailed reports of all transactions with all data.
                            </p>
                        </div>
                    </div>
                </Animation>
            </div>
            <Animation>
                <div className="flex flex-col-1 min-h-screen items-center text-center md:pb-64 pb-48 bg-center bg-no-repeat bg-cover bg-[linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0.96),rgba(0,0,0,0.86),rgba(0,0,0,0.24),rgba(0,0,0,1)),url('../public/images/2.png')]">
                    <div className="mx-auto md:min-h-96 min-h-80">
                        <h3 className="md:text-4xl text-3xl text-center text-white pb-4">Get started today</h3>
                        <p className="md:text-xl text-lg font-medium text-platinum opacity-90">Simply complete a few fields.</p>
                        <div className="pt-8"><LinkProminentButton text="Apply now" url="/apply" /></div>
                    </div>
                </div>
            </Animation>
        </DefaultLayout>
    );
}
