import { Link, NavLink} from "react-router-dom";

export default function Nav({ isVisible, setIsVisible }) {

    const onClick = () => {
        if (isVisible) {
            document.getElementById('bottom-close')?.beginElement();
            document.getElementById('top-close')?.beginElement();
        } else {
            document.getElementById('bottom-open')?.beginElement();
            document.getElementById('top-open')?.beginElement();
        }
        setIsVisible(!isVisible);
    };

    return (
        <div className={isVisible ? 'fixed top-0 left-0 inset-x-0 inset-y-0 z-[9999] bg-black md:relative w-full verflow-scroll ' : ''}>
            <nav className="mx-auto max-w-4xl w-full md:flex md:items-stretch md:gap-7 py-6 text-sm">
                <div className={isVisible ? "logo hidden md:flex items-center mr-auto float-left px-8" : "logo flex items-center mr-auto float-left px-8"}>
                    <Link to="/" className="flex items-center md:font-normal font-medium">The mighty kittens</Link>
                </div>
                <div onClick={onClick} className="float-right items-center md:hidden px-8 cursor-pointer">
                    <svg className="w-[22px] h-[22px]" viewBox="0 0 18 18">
                        <polyline fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="2 12, 16 12">
                            <animate id="bottom-open" attributeName="points" keyTimes="0;0.5;1" dur="0.140s" begin="indefinite" fill="freeze" calcMode="spline" keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1" values="2 12, 16 12; 2 9, 16 9; 3.5 15, 15 3.5"></animate>
                            <animate id="bottom-close" attributeName="points" keyTimes="0;0.5;1" dur="0.140s" begin="indefinite" fill="freeze" calcMode="spline" keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1" values="3.5 15, 15 3.5; 2 9, 16 9; 2 12, 16 12"></animate>
                        </polyline>
                        <polyline fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="2 5, 16 5">
                            <animate id="top-open" attributeName="points" keyTimes="0;0.5;1" dur="0.140s" begin="indefinite" fill="freeze" calcMode="spline" keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1" values="2 5, 16 5; 2 9, 16 9; 3.5 3.5, 15 15"></animate>
                            <animate id="top-close" attributeName="points" keyTimes="0;0.5;1" dur="0.140s" begin="indefinite" fill="freeze" calcMode="spline" keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1" values="3.5 3.5, 15 15; 2 9, 16 9; 2 5, 16 5"></animate>
                        </polyline>
                    </svg>
                </div>
                <div className={isVisible ? "md:flex clear-both md:gap-x-10 md:text-xs md:pt-0 pt-4 text-2xl font-medium items-center" : "hidden md:flex clear-both md:gap-x-10 md:text-sm md:pt-0 pt-4 text-2xl items-center"}>
                    <div className="md:flex items-center text-center">
                        { isVisible ? (<a className="block md:px-0 md:py-0 md:mt-0 px-8 py-6 mt-6" href="/overview">Overview</a>) : (<Link className="block md:px-0 md:py-0 md:mt-0 px-8 py-6 mt-6" to="/overview">Overview</Link>) }
                    </div>
                    <div className="md:flex items-center text-center">
                        { isVisible ? (<a className="block md:px-0 md:py-0 px-8 py-6" href="/license">Licensing</a>) : (<NavLink className="block md:px-0 md:py-0 px-8 py-6" to="/license">Licensing</NavLink>) }
                    </div>
                    <div className="md:flex items-center text-center whitespace-nowrap">
                        { isVisible ? (<a className="block md:px-0 md:mr-8 md:py-0 px-8 py-6" href="/apply">Get started</a>) : (<NavLink className="block md:px-0 md:mr-8 md:py-0 px-8 py-6" to="/apply">Get started</NavLink>) }
                    </div>
                </div>
            </nav>
        </div>
    );
}