import { useEffect } from "react";
import ErrorLayout from "./../layouts/error";
// import { useRouteError } from "react-router-dom";

export default function NotFound() {
    
    useEffect(() => { document.title = 'Page Not Found'; }, []);

    // const error = useRouteError();
    // console.error(error);
    
    return (
        <ErrorLayout text="The page you’re looking for can’t be found."/>
    );

    return (
        <div className="flex flex-col min-h-screen bg-black">
            <header></header>
            <main className="flex-grow content-center">
                <div className="mx-auto max-w-3xl px-8 py-8">
                    <h2 className="text-center text-[36px] sm:text-[45px] md:text-[50px]">The page you’re looking for can’t be found.</h2>
                </div>
            </main>
            <footer></footer>
        </div>
    );
}
