export default function ErrorLayout({text}) {
    return (
        <div className="flex flex-col min-h-screen bg-no-repeat bg-cover bg-[linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,1),rgba(0,0,0,0.5),rgba(0,0,0,1)),url('../public/images/1.png')] bg-center">
            <header></header>
            <main className="flex-grow content-center- mx-auto max-w-5xl px-8 pt-48 pb-16">
                <h1 className="text-center md:text-4xl text-4xl px-8 py-8 pb-16">{ text }</h1>
            </main>
            <footer></footer>  
        </div>
    );
}